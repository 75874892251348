import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Container, Grid, Divider} from "@material-ui/core";
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import showdown from 'showdown';
// import ScrollAnimation from 'react-animate-on-scroll';
// import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
// import { scroller } from 'react-scroll';

// eslint-disable-next-line 
export const TermsConditionsPageTemplate = ({ 
  heading, subheading, content
 }) => {

  const converter = new showdown.Converter();


  return (
    <>
      <FixedNavbar />
        <section className="visit" style={{marginTop:"60px"}}>
          <Container maxWidth="md">
            <Grid container spacing={2} className="alignContentCenter">
              <Grid item xs={12}>
                <Divider className="sectionHeaderLine" />
                {heading && (
                  <h2 style={{marginBottom:"24px"}}>
                    {heading}
                  </h2>
                )}
                <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
              </Grid>
             </Grid>
          </Container>
        </section>
      <Footer />
    </>
  );
};

TermsConditionsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const TermsConditionsPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return (
    <Layout>
      {/* <TermsConditionsPageTemplate
        heading={post.frontmatter.heading}
        subheading={post.frontmatter.subheading}
        content={post.frontmatter.content}/> */}
    </Layout>
  );
};

TermsConditionsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TermsConditionsPage;

export const TermsConditionsPageQuery = graphql`
  query TermsConditionsPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "termsconditions-page"}}) {
      frontmatter {
       heading,
       subheading,
       content
      }
    }
  }
`;

